 /* Container styling */
 .device {
    width: 340px; /* desired width */
    height: 600px; /* desired height */
    border: 2px solid #000; /* Simple border to emulate device frame */
    overflow: hidden; /* Hide any overflowing content */
    margin: 20px; /* Just for spacing */
    resize: both; /* Allow manual resizing */
    position: relative; /* For child positioning */
}

.main-container {
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    /* You can also set other styles like background color or image, etc. */
  }

.device-iphone14promax {
    width: 390px; /* desired width */
    height: 600px; /* desired height */
    border: 2px solid #000; /* Simple border to emulate device frame */
    overflow: hidden; /* Hide any overflowing content */
    margin: 20px; /* Just for spacing */
    resize: both; /* Allow manual resizing */
    position: relative; /* For child positioning */
}

/* Screen content styling */
.device-content {
    width: 100%;
    height: 100%;
    background: white;
    
}

/* Container for the columns */
.column-container {
    display: flex; /* Use flexbox to create a two-column layout */
    justify-content: space-between; /* Space between the columns */
  }
  
  /* Individual column styling */
  .column {
    flex: 1; /* Distribute available space evenly between columns */
    margin-right: 10px; /* Add some spacing between columns if needed */
    border: 1px solid #ccc; /* Add a border to separate columns */
    box-sizing: border-box; /* Include border in column width */
    width: 100%;
    height: 100%;
    background: white;
  }
  

.device-iphone14promax, .device-content {
    box-sizing: border-box;
}

/* Example of scaling for a larger resolution */
.scaled .device-content {
    transform: scale(0.5); /* 50% of the original size */
    transform-origin: top left; /* Start scaling from the top left corner */
}